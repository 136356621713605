<template>
    <header class="header">
        <div class="container">
            <div class="header_inner">
                <div class="flex items-center">
                    <router-link
                        v-if="logo"
                        :to="{name: frontPage}"
                        class="logo header__item">
                        <img
                            :data-src="logo"
                            class="lazyload">
                    </router-link>
                </div>
                <div class="flex items-center">
                    <div class="actions_block">
                        <component :is="returnCart" />
                        <component :is="cart" />
                        <component :is="notification" />
                        <a-avatar
                            v-if="user"
                            @click="pushProfile"
                            :key="user.avatar ? user.avatar.path : false"
                            class="cursor-pointer header__item"
                            :size="35"
                            :src="user.avatar && user.avatar.path">
                            <i class="fi fi-rr-user"></i> 
                        </a-avatar>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import mixins from './mixins'
import { mapState } from 'vuex'
export default {
    mixins: [
        mixins
    ],
    props: {
        config: {
            type: Object,
            default: () => {}
        },
        user: {
            type: Object,
            default: () => {}
        },
    },
    computed: {
        ...mapState({
            routers: state => state.navigation.routerList
        }),
        logo() {
            if(this.config?.header_setting?.logo)
                return this.config.header_setting.logo
            else
                return null
        },
        logoMini() {
            if(this.config?.header_setting?.logo_mini)
                return this.config.header_setting.logo_mini
            else
                return this.logo
        },
        frontPage() {
            if(this.routers?.length) {
                const footerRouter = [...this.$store.state.navigation.routerList].filter(f => f.isShowMobile && f.isFooter)
                if(footerRouter.length) {
                    return footerRouter[0].name
                } else {
                    return this.routers.filter(f => f.isShowMobile)[0].name
                }
            } else
                return ''
        }
    },
    methods: {
        pushProfile() {
            if(this.$route.name !== 'profile')
                this.$router.push({ name: 'profile' })
        }
    },
}
</script>

<style scoped lang="scss">
.logo{
    display: block;
    img{
        max-height: 49px;
    }
}
.header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 600;

    height: var(--headerHeight);
    min-height: var(--headerHeight);
    width: 100%;
    
    background-color: #fff;
}
.contaier {
    height: 100%;
    max-width: 900px;
}
.header_inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    height: 100%;

}
.header__item + .header__item {
    margin-left: 0.8rem;
}
.icon {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 35px;
    width: 35px;
    
    color: var(--text);

    border-radius: 50%;
    background-color: var(--iconBG);
}
.logo-img {
    max-width: 35px;
}

.fi {
    font-size: 1.2rem;
    vertical-align: middle;
}
.ant-avatar{
    background-color: #eff2f5;
    color: var(--text);
}



.actions_block{
    display: flex;
    align-items: center;
    .action_btn{
        &:not(:last-child){
            margin-right: 15px;
        }
    }
    &::v-deep{
        .ant-btn{
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background: #eff2f5;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
        }
    }
}
</style>